import React, { createContext, FC, useContext } from 'react';
import Cookies from 'js-cookie';

export type PublisherName =
  | 'default'
  | 'bgsb'
  | 'muk'
  | 'muq'
  | 'wewo' // default
  | 'orga'
  | 'trex'
  | 'grt'
  | 'orn'
  | 'grwo' //default
  | 'sup'
  | 'cig'
  | 'ff';

// Cookie served by backend, contains publisher code
export const publisher = (Cookies.get('publisher')?.toLowerCase() ??
  'default') as PublisherName;

export const defaultLanguage = Cookies.get('default_locale');
console.log('App running for publisher', publisher);

type Config = {
  code: PublisherName;
  logoPosition?: 'top-left' | 'top-center' | 'top-right' | 'legacy';
  fullName: string;
  // Display
  phone: string;
  // Real value used in link
  phoneValue: string;
  email: string;
  locales: string[];
  links: {
    subscriptionVariants: string;
    privacy: string;
    webpage: string;
  };
};

export const isLogoLegacy = (config: Config) =>
  config.logoPosition === 'legacy';

export const useLogoPosition = () => {
  const { locales, logoPosition } = useConfig();

  if (locales.length > 1) {
    return 'space-between';
  }

  switch (logoPosition) {
    case 'top-right':
      return 'flex-end';
    case 'top-left':
      return 'flex-start';
    case 'top-center':
      return 'center';
    default:
      return 'top-left';
  }
};

// Config per publisher
// key - publisher name provided via cookie by backend which serves UI
// value - publisher configuration
export const configs: { [key in PublisherName]: Config } = {
	ff: {
		code: 'ff',
		fullName: 'ff - Das Südtiroler Wochenmagazin',
		email: 'abo@ff-bz.com',
		phone: '+39 0471 304555',
		phoneValue: '+39 0471 304555'.replace(/ /g, ''),
		links: {
			privacy: 'https://www.ff-bz.com/datenschutz.html',
			subscriptionVariants: 'https://www.ff-bz.com/abo.html',
			webpage: 'https://www.ff-bz.com/',
		},
		locales: ['de'],
	},
	cig: {
		code: 'cig',
		fullName: 'Cigar',
		email: 'abo.cigar@galledia.ch',
		phone: '+41 58 344 95 37',
		phoneValue: '+41 58 344 95 37'.replace(/ /g, ''),
		links: {
			privacy: 'https://www.cigar.ch/kontakt/datenschutz/',
			subscriptionVariants: 'https://cigar.ch/services/abonnieren/',
			webpage: 'https://cigar.ch/',
		},
		locales: ['de'],
	},
	sup: {
		code: 'sup',
		fullName: 'Salz & Pfeffer',
		email: 'abo.salz-pfeffer@galledia.ch',
		phone: '+41 58 344 95 38',
		phoneValue: '+41 58 344 95 38'.replace(/ /g, ''),
		links: {
			privacy: 'https://www.salz-pfeffer.ch/datenschutz/',
			subscriptionVariants: 'https://salz-pfeffer.ch/services/abonnieren/',
			webpage: 'https://salz-pfeffer.ch/',
		},
		locales: ['de'],
	},
	grwo: {
		code: 'grwo',
		fullName: 'W&O',
		email: 'abo@wundo.ch',
		phone: '+41 81 750 02 01',
		phoneValue: '+41 81 750 02 01'.replace(/ /g, ''),
		links: {
			privacy: 'https://www.wundo.ch/datenschutzerklaerung/',
			subscriptionVariants: 'https://www.wundo.ch/abo-bestellen/',
			webpage: 'https://www.wundo.ch/',
		},
		locales: ['de'],
	},
	orn: {
		code: 'orn',
		fullName: 'ornis.ch',
		email: 'abo.ornis@galledia.ch',
		phone: '+41 58 344 95 27',
		phoneValue: '+41 58 344 95 27'.replace(/ /g, ''),
		links: {
			privacy: 'https://www.ornis.ch/datenschutz/',
			subscriptionVariants: 'https://www.ornis.ch/services/abonnieren/',
			webpage: 'https://www.ornis.ch',
		},
		locales: ['de'],
	},
  // grt is not implemented, using defaults
  grt: {
    code: 'grt',
    fullName: 'rheintaler.ch',
    email: 'abo@rheintaler.ch',
    phone: '+41 71 747 22 22',
    phoneValue: '+41 71 747 22 22'.replace(/ /g, ''),
    links: {
      privacy: 'https://www.rheintaler.ch/datenschutzerklaerung/',
      subscriptionVariants: 'https://www.rheintaler.ch/abo-bestellen/',
      webpage: 'https://www.rheintaler.ch/',
    },
    locales: ['de'],
  },
  trex: {
    code: 'trex',
    fullName: 'TREX',
    email: 'abo.trex@galledia.ch',
    phone: '+41 58 344 95 29',
    phoneValue: '+41 58 344 95 29'.replace(/ /g, ''),
    links: {
      privacy: 'https://www.trex.ch/de/p/datenschutzerklaerung/',
      subscriptionVariants: 'https://www.trex.ch/de/p/abonnieren/',
      webpage: 'https://www.trex.ch/',
    },
    locales: ['de', 'fr'],
  },
  bgsb: {
    code: 'bgsb',
    logoPosition: 'legacy',
    fullName: 'Schweizer Bauer',
    email: 'abo@schweizerbauer.ch',
    phone: '0844 10 20 30',
    phoneValue: '0844 10 20 30'.replace(/ /g, ''),
    links: {
      privacy:
        'https://www.schweizerbauer.ch/datenschutz',
      subscriptionVariants:
        'https://www.schweizerbauer.ch/abo',
      webpage: '',
    },
    locales: ['de'],
  },
  // wewo is not implemented, using defaults
  wewo: {
    code: 'wewo',
    fullName: 'ASMIQ I/O',
    email: 'info@asmiq.io',
    phone: '+41 58 344 96 96',
    phoneValue: '+41 58 344 96 96'.replace(/ /g, ''),
    links: {
      privacy: 'https://asmiq.io/datenschutzerklaerung/',
      subscriptionVariants: 'https://asmiq.io/#produkt',
      webpage: 'https://asmiq.io/',
    },
    locales: ['de', 'fr', 'en', 'it'],
  },
  // muk is not implemented, using defaults
  muk: {
    code: 'muk',
        fullName: 'm&k',
        email: 'abo.mk@galledia.ch',
        phone: '+41 58 344 95 66',
        phoneValue: '+41 58 344 95 66'.replace(/ /g, ''),
        links: {
          privacy: 'https://markt-kom.com/de/datenschutz/',
          subscriptionVariants: 'https://shop.markt-kom.com',
          webpage: 'https://markt-kom.com',
    },
    locales: ['de'],
  },
  muq: {
    code: 'muq',
    logoPosition: 'top-left',
    fullName: 'Management und Qualität',
    email: 'abo.mq@galledia.ch',
    phone: '+41 58 344 95 64',
    phoneValue: '+41 58 344 95 64'.replace(/ /g, ''),
    links: {
      privacy: 'https://www.m-q.ch/datenschutz/',
      subscriptionVariants: 'https://www.m-q.ch/abo-bestellung/',
      webpage: '',
    },
    locales: ['de'],
  },
  orga: {
    code: 'orga',
    fullName: 'Organisator',
    email: 'abo.organisator@galledia.ch',
    phone: '+41 58 344 95 66',
    phoneValue: '+41 58 344 95 66'.replace(/ /g, ''),
    links: {
      privacy: 'https://www.organisator.ch/datenschutz/',
      subscriptionVariants: 'https://www.organisator.ch/abo-bestellung/',
      webpage: '',
    },
    locales: ['de'],
  },
  default: {
    code: 'default',
    fullName: 'ASMIQ I/O',
    email: 'info@asmiq.io',
    phone: '+41 58 344 96 96',
    phoneValue: '+41 58 344 96 96'.replace(/ /g, ''),
    links: {
      privacy: 'https://asmiq.io/datenschutzerklaerung/',
      subscriptionVariants: 'https://asmiq.io/#produkt',
      webpage: 'https://asmiq.io/',
    },
    locales: ['de', 'fr', 'en', 'it'],
  },
};

const ConfigContext = createContext<Config>(null as any);

export const ConfigProvider: FC = ({ children }) => {
  return (
    <ConfigContext.Provider value={configs[publisher]}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const config = useContext(ConfigContext);
  return config;
};
